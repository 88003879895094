import React, { useState } from 'react'
import GoTrue from 'gotrue-js'

import {
  TextField,
  Button,
  Box,
  CircularProgress,
  Backdrop,
  Snackbar,
  Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

const auth = new GoTrue({
  APIUrl: 'https://rise.trinet.com/.netlify/identity',
  setCookie: true,
})

const PasswordRecovery = () => {
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
  })
  const [recoveryToken, setRecoveryToken] = useState(undefined)
  const [newPassword, setNewPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({ isError: false })

  const sendToLogin = () => {
    window.location.href = '/auth/login'
    window.history.pushState('login', 'Login', '/auth/login')
  }

  if (typeof window !== 'undefined' && recoveryToken === undefined) {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get('recovery_token')) {
      setRecoveryToken(urlParams.get('recovery_token'))
    } else {
      setRecoveryToken(false)
    }
  }

  if (recoveryToken && !newPassword) {
    setLoading(true)
    setNewPassword(true)

    auth
      .recover(recoveryToken)
      .then(() => {
        setLoading(false)
      })
      .catch(() => {
        setError({
          isError: true,
          message:
            'Error authenticating password reset link, please try again.',
        })
        setTimeout(sendToLogin, 3000)
      })
  }

  const handleSubmit = (evt) => {
    const { email, password } = credentials
    evt.preventDefault()
    setLoading(true)

    if (newPassword) {
      const user = auth.currentUser()

      user
        .update({ password })
        .then(sendToLogin)
        .catch(() => {
          setLoading(false)
          setError({
            isError: true,
            message: 'Error updating password, please try again.',
          })
        })
    } else {
      auth
        .requestPasswordRecovery(email)
        .then(sendToLogin)
        .catch(() => {
          setLoading(false)
          setError({
            isError: true,
            message: 'Error sending recovery mail, please try again.',
          })
        })
    }
  }

  const handleChange = (evt) => {
    const { name, value } = evt.target
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }))
  }

  const handleClose = () => {
    setError({ isError: false })
  }

  if (recoveryToken === undefined) return null
  return (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Snackbar open={error.isError} onClose={handleClose}>
        <Alert variant="filled" severity="error">
          {error.message}
        </Alert>
      </Snackbar>
      <Backdrop open={loading}>
        <CircularProgress />
      </Backdrop>
      <Box component="form" minWidth="350px" onSubmit={handleSubmit}>
        <Typography variant="h6" component="h6" gutterBottom>
          Password Recovery
        </Typography>
        <Box mb={3}>
          {recoveryToken ? (
            <TextField
              type="password"
              label="New password"
              name="password"
              value={credentials.password}
              onChange={handleChange}
              required
            />
          ) : (
            <TextField
              label="email"
              name="email"
              value={credentials.email}
              onChange={handleChange}
              required
            />
          )}
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            width="100%"
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default PasswordRecovery
